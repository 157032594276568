
  .ul {
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  .li {
    display: inline-block;
    list-style: none;
    position: relative;
    margin: 0 7px;
    transition: background-color 0.3s;
  }

  .a {
    color: rgba(102, 102, 102, 0.85);
    transition: all 0.2s;
    vertical-align: middle;
    font-size: 20px;
  }

  .a:hover {
    color: rgba(17, 17, 17, 0.85);
    transition: color 0.5s;
  }

  .devider {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    height: 30px;
    vertical-align: middle;
    position: relative;
    margin: 0 7.5px;
    display: inline-block;
    list-style: none;
    padding: 0;
    transition: background-color 0.3s;
  }

  .mobileUl {
    flex-wrap: nowrap;
    justify-content: flex-end;
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    order: -1;
  }

  .mobileLi {
    display: inline-block;
    list-style: none;
    padding: 0;
    position: relative;
    margin: 0 7px;
    transition: background-color 0.3s;
  }

  .mobileLink {
    font-weight: 700;
    color: rgba(102, 102, 102, 0.85);
    transition: all 0.2s;
    padding: 10px 0;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    vertical-align: middle;
    font-size: 20px;
  }
