
  .btn {
    opacity: 0;
    bottom: 20px;
    right: 20px;
    position: fixed;
    padding: 0;
    margin: 0 0.12em;
    min-width: 3em;
    max-width: 100%;
    min-height: 3em;
    text-align: center;
    cursor: pointer;
    font-weight: 700;
    line-height: 2.19em;
    letter-spacing: 0.03em;
    pointer-events: none;
    vertical-align: middle;
    transform: translateY(30%);
    display: inline-block;
    z-index: 21;
    border-radius: 999px;
    object-fit: cover;
    border: 2px solid #777;
    background-color: transparent;
    font-size: 0.97em;
    color: #777;
    transition: color 0.3s, opacity 0.3s, transform 0.3s;
  }

  .active {
    opacity: 1;
    pointer-events: inherit;
    transform: translateY(0);
  }

  .btn:hover {
    color: #fff;
    box-shadow: none;
    border-color: #11519b;
    background-color: var(--main-bg-color);
  }
