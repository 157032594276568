
  .searchUl {
    justify-content: flex-end;
    width: 35%;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  .searchLi {
    width: 93%;
    font-size: 0.85em;
    margin: 0;
    display: inline-block;
    list-style: none;
    padding: 0;
    position: relative;
    transition: background-color 0.3s;
  }
  .widenavContainer {
    display: flex;
    align-items: center;
    background-color: #1e73be;
    z-index: 9;
    position: relative;
    min-height: 35px;
  }

  .nav {
    width: 65%;
    position: relative;
    display: inline-block;
    display: -ms-flexbox;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 0;
  }

  .li {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    transition: background-color 0.3s;
  }

  .menuItemLink {
    padding: 0 0.75em;
    display: inline-flex;
    font-size: 0.9rem;
    flex-wrap: wrap;
    line-height: 47px;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 400;
  }

  .menuItemLink:hover {
    color: #fff;
    background-color: #11519b;
  }

  .menuItemLink.active {
    color: #fff;
    background-color: #11519b;
  }

  .mobileNavUl {
    flex-wrap: nowrap;
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
  }

  .mobileNavLi {
    display: inline-block;
    list-style: none;
    padding: 0;
    position: relative;
    margin: 0 7px 0 0;
    transition: background-color 0.3s;
  }

  .navMenuOpenLink {
    font-weight: 700;
    font-size: 0.8em;
    color: rgba(102, 102, 102, 0.85);
    transition: all 0.2s;
    padding: 10px 0;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .navMenuOpenTitle {
    letter-spacing: 0.05em;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 400;
    margin-left: 5px;
  }

  .headerNavContainer {
    display: flex;
  }
