
  .addTrackForm {
    margin: 1.5rem;
  }
  .title {
    text-align: center;
  }

  .avatarUploader > .ant-upload {
    width: 128px;
    height: 128px;
  }

  .sampleUploadField > div {
    width: 100%;
  }
