
  .seekBarWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }

  .seekBarContainer {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-variant: tabular-nums;
    line-height: 1.5;
    height: 0.3rem;
    display: inline-block;
    background-color: #f5f5f5;
    border-radius: 100px;
    overflow: hidden;
    vertical-align: middle;
    -webkit-user-select: none;
    -webkit-user-drag: none;
  }
  
  .bar {
    position: absolute;
    z-index: 2;
    height: 0.3rem;
    background-color: #1e73be;
    border-radius: 100px;
  }
  
