* {
  margin: 0;
  padding: 0;
}

html {
  font-family: Lato, sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  background-attachment: fixed;
}

body {
  margin: 0;
  color: #777;
  scroll-behavior: smooth;
  line-height: 1.6;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  background-color: transparent;
  text-decoration: none;
}

a:active,
a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
  display: inline-block;
}

img {
  border-style: none;
}

button,
input,
select,
textarea {
  outline: none;
  font: inherit;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

optgroup {
  font-weight: bold;
}

button,
input,
select {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  cursor: pointer;
}

[disabled] {
  cursor: default;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

textarea {
  overflow: auto;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

hr {
  margin: 15px 0;
  border-width: 0;
  border-top: 1px solid currentColor;
  opacity: 0.1;
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
}

figure {
  margin: 0;
}

pre {
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  padding: 20px;
  width: 100%;
  overflow-x: auto;
}

p:empty {
  display: none;
}

a,
button,
input {
  touch-action: manipulation;
}

ins {
  text-decoration: none;
}

embed,
iframe,
object {
  max-width: 100%;
}

.gridContainer {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17%, 1fr));
  grid-template-rows: 1fr;
  grid-gap: 15px;
  gap: 15px;
  grid-template-areas:
          ". . . . ."
          ". . . . ."
          ". . . . .";
}

.layout-center {
  align-tems: flex-start;
  justify-content: center;
  display: flex
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 1;
}
.ant-form-item-children span.ant-form-item-children-icon {
  z-index: 2 !important;
}

.AdminCarouselManagePage .ant-upload-list-picture-card .ant-upload-list-item {
  width: 500px;
}

.AdminCarouselManagePage .ant-upload-list-picture-card-container {
  width: 500px;
}

.ant-collapse-header {
  font-weight: bold;
}

.ant-table-tbody > tr > td {
  padding: 8px 16px !important;
}

.ant-table-tbody > tr.ant-table-row-level-0:hover > td {
  background: unset !important;
}

.ant-table-row-level-0:has(.ant-table-row-expand-icon-expanded) {
  background: rgba(12%, 45%, 75%, 0.1);
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #fafafa;
}

/*table:has(.ant-table-row-expand-icon-expanded) .ant-table-expanded-row-level-1 {
  margin-bottom: 40px;
}*/


  .CartEmptyText-style_empty-cart-wrapper__14Odb {
    text-align: center;
    margin-top: 2em;
  }


  .TablePlayButton-styles_playButton__3PtGo {
    font-size: 1.7rem;
    margin: 0 0.5rem;
    border: none;
    background: none;
    opacity: 0.9;
    color: #1e73be;
  }


  .FavoritesEmptyText-style_empty-favorites-wrapper__--TGk {
    text-align: center;
    margin-top: 2em;
  }


  .VolumeButton-styles_actionButton__3puWZ {
    border: none;
    background: none;
    fill: currentColor;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
  }
  .VolumeButton-styles_actionButton__3puWZ:hover {
    fill: black;
  }


  .VolumeBar-styles_volumeBarInput__ukc00 {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0.01;
    z-index: 4;
    cursor: pointer;
    height: 1rem;
  }
  input[type='range']:focus {
    outline: none;
  }
  input[type='range']::-webkit-slider-thumb {
    height: 1px;
    width: 1px;
    border-radius: 3px;
    background: #b1ffff;
    cursor: pointer;
    -webkit-appearance: none;
  }

  .VolumeBar-styles_volumeBarWrapper__3TY3P {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .VolumeBar-styles_volumeBarContainer__BXhab {
    position: relative;
    width: 80%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    display: inline-block;
    height: 0.3rem;
    background-color: #f5f5f5;
    border-radius: 100px;
    overflow: hidden;
    vertical-align: middle;
    cursor: pointer;
  }

  .VolumeBar-styles_bar__3YgW2 {
    position: absolute;
    z-index: 2;
    background-color: #1e73be;
    border-radius: 100px;
    height: 0.3rem;
    width: 100%;
  }


  .SeekBar-styles_seekBarWrapper__3oBPZ {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
  }

  .SeekBar-styles_seekBarContainer__1D92T {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    height: 0.3rem;
    display: inline-block;
    background-color: #f5f5f5;
    border-radius: 100px;
    overflow: hidden;
    vertical-align: middle;
    -webkit-user-select: none;
    -webkit-user-drag: none;
  }
  
  .SeekBar-styles_bar__PMtKU {
    position: absolute;
    z-index: 2;
    height: 0.3rem;
    background-color: #1e73be;
    border-radius: 100px;
  }
  


  div.AudioPlayer-styles_playerWrapper__2NtZX {
    height: 5rem;
  }

  .AudioPlayer-styles_player__2MRUx {
    display: flex;
    position: fixed;
    bottom: 0;
    height: 5rem;
    width: 100%;
    background: white;
    opacity: 0.9;
    align-items: center;
    padding: 0 2rem;
    border-top: #1e73be 1px solid;
  }

  .AudioPlayer-styles_actionButton__3SE7k {
    font-size: 1.7rem;
    margin: 0 0.5rem;
    border: none;
    background: none;
    opacity: 0.9;
  }
  .AudioPlayer-styles_actionButton__3SE7k:hover {
    color: black;
  }

  .AudioPlayer-styles_playButtonsArea__1nPfu {
    width: 20%;
  }

  .AudioPlayer-styles_infoArea__2Gr3w {
    width: 50%;
    text-align: center;
    height: 100%;
  }

  .AudioPlayer-styles_progressbarArea__1C_9L {
    display: flex;
    align-items: center;
    height: 2rem;
    font-size: 0.8rem;
  }

  .AudioPlayer-styles_volumeBarArea__1MsB6 {
    width: 10%;
  }

  .AudioPlayer-styles_progressbarContainer__IhUyp {
    position: relative;
    width: 80%;
    height: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 2;
    display: inline-block;
  }
  .AudioPlayer-styles_waveBarContainer__37CtE {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    height: 32px;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 2;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -webkit-user-drag: none;
  }

  .AudioPlayer-styles_progressbarTime__fBi2E {
    width: 6%;
  }

  .AudioPlayer-styles_sampleName__2eVOr {
    height: 1.3rem;
    margin-top: 0.3rem;
  }

  .AudioPlayer-styles_waveBar__2I8AH {
    position: absolute;
    width: 100%;
    height: 2rem;
  }

  .AudioPlayer-styles_waveBar__2I8AH wave {
    z-index: 10;
  }


  .TrackTableExpandedRowContent-styles_wrapper__2ctTQ {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1500px;
  }
  
  .TrackTableExpandedRowContent-styles_sameTracksTable__XcuR_ tbody {
  	margin-left: 10px;
  }
  
  .TrackTableExpandedRowContent-styles_sameTracksTable__XcuR_  tbody .TrackTableExpandedRowContent-styles_samplesTable__2BlJo {
    width: 100% ;
  }
  
  .TrackTableExpandedRowContent-styles_sameTracksTable__XcuR_ tbody {
  	background: rgba(12%, 45%, 75%, 0.1);
  }
  
  table.TrackTableExpandedRowContent-styles_samplesTable__2BlJo {
    
  }

  .TrackTableExpandedRowContent-styles_details__pfaT5 {
    display: flex;
    flex-wrap: wrap;
    width: 82%;
  }

  .TrackTableExpandedRowContent-styles_details__pfaT5 dt {
    width: 21%;
    line-height: 1.5rem;
  }

  .TrackTableExpandedRowContent-styles_details__pfaT5 dd {
    width: 60%;
    margin: 0;
    line-height: 1.5rem;
  }

  .TrackTableExpandedRowContent-styles_samplesWrapper__1tCPq {
    width: 50%;
  }


  .TrackTableExpandedRowContent-styles_samplesTable__2BlJo td {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    width: unset;
  }
  
  .TrackTableExpandedRowContent-styles_trackExpandEl__3CHbM{
    
  }
  


  .Spinner-styles_spinner__38J1Y {
    width: 80px;
    height: 80px;
    margin: auto;
    border-radius: 50%;
    border: 10px solid #333;
    box-sizing: border-box;
    -webkit-animation: Spinner-styles_sweep__1GwD6 1s linear alternate infinite, Spinner-styles_rota__1KMeX 0.8s linear infinite;
            animation: Spinner-styles_sweep__1GwD6 1s linear alternate infinite, Spinner-styles_rota__1KMeX 0.8s linear infinite;
  }

  @keyframes Spinner-styles_rota__1KMeX {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes Spinner-styles_rota__1KMeX {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes Spinner-styles_sweep__1GwD6 {
    0% {
      -webkit-clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 50% 50%, 0% 0%, 0% 0%, 0% 0%);
              clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 50% 50%, 0% 0%, 0% 0%, 0% 0%);
    }
    50% {
      -webkit-clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 50% 50%, 100% 0%, 100% 0%, 0% 0%);
              clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 50% 50%, 100% 0%, 100% 0%, 0% 0%);
    }
    100% {
      -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 50% 50%, 100% 100%, 100% 0%, 0% 0%);
              clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 50% 50%, 100% 100%, 100% 0%, 0% 0%);
    }
  }

  @keyframes Spinner-styles_sweep__1GwD6 {
    0% {
      -webkit-clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 50% 50%, 0% 0%, 0% 0%, 0% 0%);
              clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 50% 50%, 0% 0%, 0% 0%, 0% 0%);
    }
    50% {
      -webkit-clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 50% 50%, 100% 0%, 100% 0%, 0% 0%);
              clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 50% 50%, 100% 0%, 100% 0%, 0% 0%);
    }
    100% {
      -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 50% 50%, 100% 100%, 100% 0%, 0% 0%);
              clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 50% 50%, 100% 100%, 100% 0%, 0% 0%);
    }
  }


  .HeaderIconBlock-styles_ul__1df_c {
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  .HeaderIconBlock-styles_li__1pa74 {
    display: inline-block;
    list-style: none;
    position: relative;
    margin: 0 7px;
    transition: background-color 0.3s;
  }

  .HeaderIconBlock-styles_a__3UM23 {
    color: rgba(102, 102, 102, 0.85);
    transition: all 0.2s;
    vertical-align: middle;
    font-size: 20px;
  }

  .HeaderIconBlock-styles_a__3UM23:hover {
    color: rgba(17, 17, 17, 0.85);
    transition: color 0.5s;
  }

  .HeaderIconBlock-styles_devider__1CLEP {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    height: 30px;
    vertical-align: middle;
    position: relative;
    margin: 0 7.5px;
    display: inline-block;
    list-style: none;
    padding: 0;
    transition: background-color 0.3s;
  }

  .HeaderIconBlock-styles_mobileUl__3mv3d {
    flex-wrap: nowrap;
    justify-content: flex-end;
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    order: -1;
  }

  .HeaderIconBlock-styles_mobileLi__2Zmjw {
    display: inline-block;
    list-style: none;
    padding: 0;
    position: relative;
    margin: 0 7px;
    transition: background-color 0.3s;
  }

  .HeaderIconBlock-styles_mobileLink__1McRe {
    font-weight: 700;
    color: rgba(102, 102, 102, 0.85);
    transition: all 0.2s;
    padding: 10px 0;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    vertical-align: middle;
    font-size: 20px;
  }


  .HeaderSearch-styles_searchWrapper__FhR60 {
    flex: 1 1;
  }

  .HeaderSearch-styles_search__2vQs5 {
    box-sizing: border-box;
    border: 1px solid #ddd;
    padding: 0 0.75em;
    height: 2.507em;
    font-size: 0.97em;
    max-width: 100%;
    width: 100%;
    vertical-align: middle;
    background-color: #fff;
    color: currentColor;
    transition: color 0.3s, border 0.3s, background 0.3s, opacity 0.3s;
    box-shadow: none;
    border-radius: 99px;
    cursor: text;
  }

  .HeaderSearch-styles_btnWrapper__4pGeR {
    margin-left: -32px;
  }

  .HeaderSearch-styles_btn__27hLh {
    margin: 0;
    color: currentColor;
    border-color: transparent;
    background-color: transparent;
    box-shadow: none;
    width: 32px;
    padding: 0;
    display: inline-block;
    cursor: pointer;
    height: 32px;
    font-size: 13px;
  }


  .HeaderNav-styles_searchUl__2RQ0j {
    justify-content: flex-end;
    width: 35%;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  .HeaderNav-styles_searchLi__3kEEV {
    width: 93%;
    font-size: 0.85em;
    margin: 0;
    display: inline-block;
    list-style: none;
    padding: 0;
    position: relative;
    transition: background-color 0.3s;
  }
  .HeaderNav-styles_widenavContainer__2KD3n {
    display: flex;
    align-items: center;
    background-color: #1e73be;
    z-index: 9;
    position: relative;
    min-height: 35px;
  }

  .HeaderNav-styles_nav__3oQ9V {
    width: 65%;
    position: relative;
    display: inline-block;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 0;
  }

  .HeaderNav-styles_li__2gl7s {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    transition: background-color 0.3s;
  }

  .HeaderNav-styles_menuItemLink__xDCB6 {
    padding: 0 0.75em;
    display: inline-flex;
    font-size: 0.9rem;
    flex-wrap: wrap;
    line-height: 47px;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 400;
  }

  .HeaderNav-styles_menuItemLink__xDCB6:hover {
    color: #fff;
    background-color: #11519b;
  }

  .HeaderNav-styles_menuItemLink__xDCB6.HeaderNav-styles_active__3YLU5 {
    color: #fff;
    background-color: #11519b;
  }

  .HeaderNav-styles_mobileNavUl__1_m3E {
    flex-wrap: nowrap;
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
  }

  .HeaderNav-styles_mobileNavLi__iYoQH {
    display: inline-block;
    list-style: none;
    padding: 0;
    position: relative;
    margin: 0 7px 0 0;
    transition: background-color 0.3s;
  }

  .HeaderNav-styles_navMenuOpenLink__24OMy {
    font-weight: 700;
    font-size: 0.8em;
    color: rgba(102, 102, 102, 0.85);
    transition: all 0.2s;
    padding: 10px 0;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .HeaderNav-styles_navMenuOpenTitle__k10rK {
    letter-spacing: 0.05em;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 400;
    margin-left: 5px;
  }

  .HeaderNav-styles_headerNavContainer__wqm2p {
    display: flex;
  }


  .HeaderTopBar-styles_headerTop__1Szdk {
    min-height: 30px;
    /*background-color: #f7f7f7;*/
    display: flex;
    align-items: center;
  }

  .HeaderTopBar-styles_nav__1Xmso {
    position: relative;
    display: flex;
    /*flex-flow: row wrap;*/
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;
    margin-left: 0;
  }

  .HeaderTopBar-styles_menuItem__3puLo {
    list-style: none;
    position: relative;
    margin: 0 0.7em;
    display: inline-block;
  }
  
  .HeaderTopBar-styles_menuItem__3puLo:last-child {
    margin-right: 0;
  }

  .HeaderTopBar-styles_menuLink__3tyEt {
    vertical-align: top;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: normal;
    color: rgba(102, 102, 102, 0.85);
    transition: all 0.2s;
    font-size: 0.8em;
    text-decoration: none;
    display: inline-flex;
  }

  .HeaderTopBar-styles_menuLink__3tyEt:after {
    content: '';
    position: absolute;
    top: 50%;
    width: 1px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    height: 15px;
    margin-top: -7px;
    left: -1em;
  }

  .HeaderTopBar-styles_menuLink__3tyEt:hover {
    color: rgba(17, 17, 17, 0.85);
  }


  .HeaderMasterhead-styles_ul__7XV_R {
    justify-content: flex-start;
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  .HeaderMasterhead-styles_li__1ycKz {
    width: 93%;
    font-size: 0.85em;
    margin: 0;
    display: inline-block;
    list-style: none;
    padding: 0;
    position: relative;
    transition: background-color 0.3s;
  }

  .HeaderMasterhead-styles_li__1ycKz input {
    background-color: rgba(0, 0, 0, 0.03);
    border-color: rgba(0, 0, 0, 0.09);
  }

  .HeaderMasterhead-styles_wrapper__3ZtKc {
    height: 76px;
    z-index: 10;
    position: relative;
    align-items: center;
  }

  .HeaderMasterhead-styles_logo__2bUce {
    margin: 0 30px 0 0;
    width: 200px;
    line-height: 1;
  }

  .HeaderMasterhead-styles_logoLink__DGXcI {
    display: block;
  }

  .HeaderMasterhead-styles_logoImg__uwvuu {
    width: 200px;
  }

  .HeaderMasterhead-styles_searchBlock__3Yrcp {
    margin-right: auto;
    flex: 1 1;
    flex-basis: 0;
  }

  .HeaderMasterhead-styles_icon__-K4_A {
    font-size: 20px;
  }

  .HeaderMasterhead-styles_mobileIconBlockWrapper__Z5uvY {
    order: 3;
  }

  @media screen and (max-width: 767px) {
    .HeaderMasterhead-styles_logo__2bUce {
      order: 2;
      text-align: center;
      margin: 0 15px;
    }
  }


  .Header-styles_stuckHeader__1JKBs {
    height: 153px;
  }

  .Header-styles_header__2e1Rr {
    width: 100%;
    z-index: 30;
    position: relative;
    display: block;
    background-size: cover;
    background-position: 50% 0;
    transition: background-color 0.3s, opacity 0.3s;
  }

  .Header-styles_headerWrapper__3r3Yk {
    width: 100%;
    z-index: 30;
    position: relative;
    background-size: cover;
    background-color: rgba(255, 255, 255, 0.9);
    background-position: 50% 0;
    transition: background-color 0.3s, opacity 0.3s;
  }

  .Header-styles_fill__2nE7R {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    right: 0;
    bottom: 0;
    padding: 0 !important;
    margin: 0 !important;
  }

  .Header-styles_header-bg-image__cBiu2,
  .Header-styles_header-bg-color__2eheS {
    background-position: 50% 0;
    transition: background 0.4s;
  }

  .Header-styles_header-top__1PpXZ {
    background-color: #446084;
    z-index: 11;
    position: relative;
    min-height: 20px;
  }

  @-webkit-keyframes Header-styles_stuckMoveDown__2qj9- {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes Header-styles_stuckMoveDown__2qj9- {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }



  .Header-styles_header__2e1Rr.Header-styles_show-on-scroll__2srJN .Header-styles_hide-for-sticky__IdfLy,
  .Header-styles_stuck__2DiZi .Header-styles_hide-for-sticky__IdfLy {
    display: none;
  }


  .Footer-styles_tag__1xlmK {
    color: currentColor;
    font-size: 1em;
    text-decoration: none;
    background-color: transparent;
    display: inline-block;
    border: 1px solid currentColor;
    opacity: 0.8;
    margin: 0 3px 5px 0;
    padding: 2px 5px;
    border-radius: 3px;
  }

  .Footer-styles_tag__1xlmK:hover {
    color: #fff;
    border-color: #11519b;
    background-color: #11519b;
    opacity: 1;
  }

  @-webkit-keyframes Footer-styles_fadeIn__3mPIW {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes Footer-styles_fadeIn__3mPIW {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .Footer-styles_wrapper__1joi6 {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    background-color: #254061;
    padding-left: 10px;
    font-family: ArialNarrowRegular;
    padding: 30px 0 0;
    color: #f1f1f1;
    -webkit-animation: Footer-styles_fadeIn__3mPIW 0.1s;
            animation: Footer-styles_fadeIn__3mPIW 0.1s;
  }

  .Footer-styles_widgetWrapper__36Q_e {
    text-align: left;
    margin-bottom: 2.5em;
  }

  .Footer-styles_widgetTitle__2SmmK {
    font-size: 1.2em;
    font-weight: 600;
    line-height: 1.25;
    padding-bottom: 10px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #40a9ff
  }

  .Footer-styles_widgetTitleDivider__wVs2J {
    background-color: rgba(255, 255, 255, 0.3);
    /*margin-top: .66em;*/
    height: 3px;
    display: block;
    margin: 1em 0 1em;
    width: 100%;
    max-width: 30px;
  }

  .Footer-styles_lastNewsBadgeLi__3HkCV {
    list-style: none;
    margin-bottom: 0.3em;
    text-align: left;
  }

  .Footer-styles_lastNewsBadgeLi__3HkCV:not(:first-child) {
    border-top: 1px solid #ececec;
    border-color: rgba(255, 255, 255, 0.08);
  }

  .Footer-styles_badgeWrapper__3CJzg {
    padding: 10px 0 10px;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .Footer-styles_badgeDateWrapper__1uJaB {
    margin-right: 15px;
    max-height: 100%;
  }

  .Footer-styles_badgeDate__2p_ac {
    top: 7%;
    margin: 0;
    width: 2.3em;
    height: 2.3em;
    display: table;
    z-index: 20;
    pointer-events: none;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }

  .Footer-styles_badgeDateInner__7BaF9 {
    color: #fff;
    opacity: 0.7;
    background-color: transparent;
    border: 2px solid currentColor;
    line-height: 0.7;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: 50% 50%;

    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 100%;
    height: 100%;
    font-weight: bolder;
    padding: 2px;
    white-space: nowrap;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
  }

  .Footer-styles_badgeDateMonth__2t158 {
    font-size: 0.9em;
  }

  .Footer-styles_badgeTitle__2Fq-4 {
    color: currentColor;
    line-height: 1.2;
    display: block;
    margin-bottom: 6px;
  }

  .Footer-styles_badgeComments__1ooEn {
    color: currentColor;
    opacity: 0.7;
    display: block;
    font-size: 0.9em;
    line-height: 1.2;
    margin-bottom: 6px;
  }
  
   .Footer-styles_link__3oo3E {
    color: currentColor;
    font-size: 16px;
  }
  
  ul {
    line-height: 1.2;
    margin-left: 20px;
  }

  .Footer-styles_logoImg__2QPcU{
    height: 120px;
  }

.form_privacyPolicy__1AcYF {
  font-size: 85%;
  margin-bottom: 0;
  padding-left: 10px;
}

.form_formTitle__1vt3y {
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;

  color: rgb(36, 30, 18);
  font-family: Walsheim, sans-serif;
  position: relative;
  font-weight: 700;
  font-size: 1.3125rem;
  transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
  margin: 1em 0px;
}

.form_button__3zZ-O {
  margin: 0;
  margin-top: 1em;
  background-color: rgb(245, 244, 242);
  align-items: center;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  height: unset;
  justify-content: center;
  position: relative;
  pointer-events: unset;
  text-align: center;
  text-transform: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: unset;
  width: 100%;
  color: rgb(177, 172, 163);
  font-size: 1rem;
  border-radius: 9999px;
  border-width: medium;
  border-width: initial;
  border-style: none;
  border-color: currentColor;
  border-color: initial;
  border-image: none;
  border-image: initial;
  padding: 0.875rem 1.5rem;
  text-decoration: none;
  transition: all 700ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
}

.form_buttonActive__3R0M7 {
  color: rgb(255, 255, 255);
  background-color: #11519b;
}

.form_button__3zZ-O:hover {
  box-shadow: inset 0 0 0 100px rgba(0, 0, 0, 0.2);
}

.form_input__1RMYa {
  margin: 0.5rem 0;
  resize: none;
  text-align: left;
  width: 100%;
  font-weight: normal;
  box-shadow: none;
  box-sizing: border-box;
  color: rgb(36, 30, 18);
  height: 3rem;
  font-size: 1rem;
  background: rgb(245, 244, 242);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(229, 227, 221);
  border-image: none;
  border-image: initial;
  outline: none;
  padding: 0.75rem 1rem;
  transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
  border-radius: 4px;
}

.form_input__1RMYa:focus {
  background: rgb(255, 255, 255);
  border-color: rgb(0, 76, 129);
  outline: none;
}

.form_inputErrored__3DSHf {
  background-color: rgb(250, 233, 234);
  border-color: rgb(204, 50, 63);
}

.form_label__2DJxv {
  font-weight: bold;
  display: block;
  font-size: 0.9em;
  margin-bottom: 0.2em;
  margin-top: 0.5em;
}

.form_errorMsg__tVrUP {
  color: rgb(204, 50, 63);
  font-size: 0.8em;
}

.form_formErrorMessage__1IDkQ {
  text-align: center;
  font-size: 1rem;
}

.form_formWrapper__17EmW {
  margin-top: 16px;
  margin-bottom: 16px;
}

.form_form__2Sxui {
  padding: 1.5rem;
  box-shadow: none;
  background-color: rgb(255, 255, 255);
  border-top: 1px solid rgb(229, 227, 221);
  border-bottom: 1px solid rgb(229, 227, 221);
  border-left: 1px solid rgb(229, 227, 221);
  border-right: 1px solid rgb(229, 227, 221);
  border-radius: 4px;
  overflow: hidden;
}

.form_fieldWrapper__3w3P0 {
  padding-bottom: 0.5rem;
}

.form_lastText__1qicw {
  margin-top: 10px;
  text-align: center;
}

.form_link__DdqA0 {
  color: #11519b;
  text-decoration: underline;
}


  .BackToTopArrow-styles_btn__1Cyyf {
    opacity: 0;
    bottom: 20px;
    right: 20px;
    position: fixed;
    padding: 0;
    margin: 0 0.12em;
    min-width: 3em;
    max-width: 100%;
    min-height: 3em;
    text-align: center;
    cursor: pointer;
    font-weight: 700;
    line-height: 2.19em;
    letter-spacing: 0.03em;
    pointer-events: none;
    vertical-align: middle;
    transform: translateY(30%);
    display: inline-block;
    z-index: 21;
    border-radius: 999px;
    object-fit: cover;
    border: 2px solid #777;
    background-color: transparent;
    font-size: 0.97em;
    color: #777;
    transition: color 0.3s, opacity 0.3s, transform 0.3s;
  }

  .BackToTopArrow-styles_active__2v77c {
    opacity: 1;
    pointer-events: inherit;
    transform: translateY(0);
  }

  .BackToTopArrow-styles_btn__1Cyyf:hover {
    color: #fff;
    box-shadow: none;
    border-color: #11519b;
    background-color: var(--main-bg-color);
  }


  .AbsoluteFooter-styles_ul__RQGb2 {
    padding-bottom: 5px;
    opacity: 0.8;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    margin: 5px 0;
    font-size: 0.85em;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  .AbsoluteFooter-styles_li__2mwn4 {
    display: inline-block;
  }

  .AbsoluteFooter-styles_li__2mwn4:not(:first-child) {
    margin: 0 0.4em;
  }

  .AbsoluteFooter-styles_link__3vtZG {
    color: currentColor;
  }

  .AbsoluteFooter-styles_wrapper__3fXZx {
    color: white;
    padding: 15px 0;
    font-family: ArialNarrowRegular;
    font-size: 1.2em;
    background-color: #2670c0;
  }

  @media (max-width: 767px) {
    .AbsoluteFooter-styles_wrapper__3fXZx {
      text-align: center;
    }
  }

  .AbsoluteFooter-styles_paymentIconsWrapper__3bMOP {
    display: inline-block;
    color: rgba(255, 255, 255, 0.5);
  }
  
  .AbsoluteFooter-styles_paymentImage__1BwpG {
    height: 40px
  }

  @media (min-width: 768px) {
    .AbsoluteFooter-styles_paymentIconsWrapper__3bMOP {
      text-align: right;
    }
  }

  .AbsoluteFooter-styles_paymentIcon__2ZnkB {
    display: inline-block;
    opacity: 0.6;
    transition: opacity 0.3s;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 3px 5px 5px;
    margin: 3px 3px 0;
    font-size: 0.9em;
  }

  .AbsoluteFooter-styles_paymentIconSvg__2bB_a {
    fill: #fff;
    vertical-align: middle;
    height: 20px;
  }


  .RegularPageTemplate-styles_gap__1b9BG {
    display: block;
    height: auto;
    padding-top: 44px;
  }

  .RegularPageTemplate-styles_main__1ZiZ1 {
    width: 100%;
    margin: auto;
    padding: 24px 2%;
  }


  .MainPageGenres-styles_direction__1Gskt {
  	transition: all ease 0.3s;
  	aspect-ratio: 1;
  	
  	background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 0 7px rgba(0,0,0,0.3);
    display: flex;
    /*align-items: center;
    justify-content: center;*/
    cursor: pointer;
    /*padding: 16px;*/
    border-radius: 3px;
    
    -webkit-filter: brightness(1) saturate(.5);
    
            filter: brightness(1) saturate(.5);
  }
  
  .MainPageGenres-styles_directionName__dp_Q2 {
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
    //margin-top: 16px;
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .MainPageGenres-styles_direction__1Gskt:hover .MainPageGenres-styles_directionImg__BwDQp:not(.MainPageGenres-styles_directionName__dp_Q2) {
  transition: all ease 0.3s;
  	transform: scale(1.05);
  }
  
  .MainPageGenres-styles_direction__1Gskt:hover {
  	box-shadow: 0 0 15px rgba(0,0,0,0.3);
  	-webkit-filter: brightness(1.1) saturate(1);
  	        filter: brightness(1.1) saturate(1);
  	//background-size: 105%;
  }



  .MainPage-styles_genres__3LAkr {
    padding: 40px 0 40px 0;
    background: #fafafa;
  }
  
  .MainPage-styles_genresTitle__23a0a {
    text-align: center;
  }


  .ProfilePage-styles_profileForm__kXMiX {
    max-width: 600px;
  }

.onboarding_stepsContentWrapper__1vGhc {
  margin: auto;
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  max-width: 920px;
}

.onboarding_stepsContentWrapperBlue__3dX5h {
  margin-top: 16px;
  border: 1px dashed #2060a8;
  border-radius: 6px;
  background-color: #2060a8;
  min-height: 200px;
  text-align: center;
  max-width: 920px;
}


.onboarding_stepsAction__2cisE {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.onboarding_wizardFormBlue__38-GB {
  margin: 1.5rem;
  text-align: left;
  background-color: #2060a8;
}


.onboarding_wizardForm__3U5kG {
  margin: 1.5rem;
  text-align: left;
}



.onboarding_stepActionButton__2jXI- {
  margin: 0 1rem;
}


  .ComposerINNPage-styles_form__1mo7Q {
    margin: 1.5rem;
  }


  .InstrumentTable-styles_actionsCell__1_s4z {
    display: flex;
    justify-content: space-evenly;
  }


  .GenresTable-styles_actionsCell__1ouDP {
    display: flex;
    justify-content: space-evenly;
  }


  .DirectionsTable-styles_actionsCell__1hB_3 {
    display: flex;
    justify-content: flex-start;
  }
  .DirectionsTable-styles_actionsCell__1hB_3 button {
     margin-right: 10px
  }


  .MoodsTable-styles_actionsCell__Bahna {
    display: flex;
    justify-content: space-evenly;
  }


  .ComposerPassportPage-styles_form__cTiuA {
    margin: 1.5rem;
  }


  .PublishersTable-styles_actionsCell__3ecVH {
    display: flex;
    justify-content: space-evenly;
  }


  :root {
    --main-bg-color: #1e73be;
    --default-page-padding: 24px 2%;
  }


  .TrackSiderFilterWrapper-style_trackSiderFilterWrapper__2Oa5r {
    padding: 0 10px;
  }


  .SearchEmptyText-style_wrapper__pmfGJ {
    text-align: center;
    margin-top: 2em;
  }


  .UploadSamplesList-styles_uploadListWrapper__2Ttwy {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum', "tnum";
    zoom: 1;
  }

  .UploadSamplesList-styles_uploadListItem__34zp3 {
    position: relative;
    height: 66px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-top: 8px;
    font-size: 14px;
  }

  .UploadSamplesList-styles_uploadListItemInfo__3vEeD {
    height: 100%;
    padding: 0 1.2rem 0 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: background-color 0.3s;
  }

  .UploadSamplesList-styles_uploadListItemName__1ycPO {
    width: 100%;
    max-width: 100%;
    margin: 0 0 0 8px;
    padding-right: 8px;
    overflow: hidden;
    line-height: 2rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.3s;
  }

  .UploadSamplesList-styles_uploadListItemCloseButton__1ZJiP {
    font-size: 12px;
    position: absolute;
    top: 8px;
    right: 8px;
    line-height: 1;
    opacity: 1;
  }


  .UploadSampleButton-styles_sampleUploadButton__1XUQI {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.8rem 0.8rem 0.8rem 0;
    text-align: center;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    transition: border-color 0.3s ease;
    width: 100%;
    height: 4rem;
    vertical-align: middle;
  }

  .UploadSampleButton-styles_sampleUploadButton__1XUQI:hover {
    border-color: #1890ff;
  }


  .SamplesField-styles_avatarUploader__fNdjj > .SamplesField-styles_ant-upload__NuR12 {
    width: 128px;
    height: 128px;
  }

  .SamplesField-styles_sampleUploadField__qBaz3 > div {
    width: 100%;
  }


  .MainSampleField-styles_avatarUploader__1YHev > .MainSampleField-styles_ant-upload__1KcJL {
    width: 128px;
    height: 128px;
  }

  .MainSampleField-styles_sampleUploadField__3zFxW > div {
    width: 100%;
  }
    .MainSampleField-styles_sampleUploadButton__1hc2e {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.8rem 0.8rem 0.8rem 0;
    text-align: center;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    transition: border-color 0.3s ease;
    width: 100%;
    height: 4rem;
    vertical-align: middle;
  }

  .MainSampleField-styles_sampleUploadButton__1hc2e:hover {
    border-color: #1890ff;
  }


  .AdminTrackEditPage-styles_addTrackForm__2KoU3 {
    margin: 1.5rem;
  }
  .AdminTrackEditPage-styles_title__3OLR_ {
    text-align: center;
  }

  .AdminTrackEditPage-styles_avatarUploader__28a9U > .AdminTrackEditPage-styles_ant-upload__1Zokj {
    width: 128px;
    height: 128px;
  }

  .AdminTrackEditPage-styles_sampleUploadField__1oFcL > div {
    width: 100%;
  }


  .ComposerAddTrackPage-styles_off__addTrackForm__3kzlo {
    margin: 1.5rem;
  }
  .ComposerAddTrackPage-styles_title__3hELG {
    text-align: center;
  }


  .ComposerTrackEditPage-styles_addTrackForm__3tr9Y {
    margin: 1.5rem;
  }
  .ComposerTrackEditPage-styles_title__1sAbg {
    text-align: center;
  }

  .ComposerTrackEditPage-styles_avatarUploader__2ntjS > .ComposerTrackEditPage-styles_ant-upload__1ed3H {
    width: 128px;
    height: 128px;
  }

  .ComposerTrackEditPage-styles_sampleUploadField__1kW_p > div {
    width: 100%;
  }

