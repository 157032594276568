.privacyPolicy {
  font-size: 85%;
  margin-bottom: 0;
  padding-left: 10px;
}

.formTitle {
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;

  color: rgb(36, 30, 18);
  font-family: Walsheim, sans-serif;
  position: relative;
  font-weight: 700;
  font-size: 1.3125rem;
  transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
  margin: 1em 0px;
}

.button {
  margin: 0;
  margin-top: 1em;
  background-color: rgb(245, 244, 242);
  align-items: center;
  backface-visibility: hidden;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  height: unset;
  justify-content: center;
  position: relative;
  pointer-events: unset;
  text-align: center;
  text-transform: none;
  user-select: none;
  white-space: unset;
  width: 100%;
  color: rgb(177, 172, 163);
  font-size: 1rem;
  border-radius: 9999px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  padding: 0.875rem 1.5rem;
  text-decoration: none;
  transition: all 700ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
}

.buttonActive {
  color: rgb(255, 255, 255);
  background-color: #11519b;
}

.button:hover {
  box-shadow: inset 0 0 0 100px rgba(0, 0, 0, 0.2);
}

.input {
  margin: 0.5rem 0;
  resize: none;
  text-align: left;
  width: 100%;
  font-weight: normal;
  box-shadow: none;
  box-sizing: border-box;
  color: rgb(36, 30, 18);
  height: 3rem;
  font-size: 1rem;
  background: rgb(245, 244, 242);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(229, 227, 221);
  border-image: initial;
  outline: none;
  padding: 0.75rem 1rem;
  transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
  border-radius: 4px;
}

.input:focus {
  background: rgb(255, 255, 255);
  border-color: rgb(0, 76, 129);
  outline: none;
}

.inputErrored {
  background-color: rgb(250, 233, 234);
  border-color: rgb(204, 50, 63);
}

.label {
  font-weight: bold;
  display: block;
  font-size: 0.9em;
  margin-bottom: 0.2em;
  margin-top: 0.5em;
}

.errorMsg {
  color: rgb(204, 50, 63);
  font-size: 0.8em;
}

.formErrorMessage {
  text-align: center;
  font-size: 1rem;
}

.formWrapper {
  margin-top: 16px;
  margin-bottom: 16px;
}

.form {
  padding: 1.5rem;
  box-shadow: none;
  background-color: rgb(255, 255, 255);
  border-top: 1px solid rgb(229, 227, 221);
  border-bottom: 1px solid rgb(229, 227, 221);
  border-left: 1px solid rgb(229, 227, 221);
  border-right: 1px solid rgb(229, 227, 221);
  border-radius: 4px;
  overflow: hidden;
}

.fieldWrapper {
  padding-bottom: 0.5rem;
}

.lastText {
  margin-top: 10px;
  text-align: center;
}

.link {
  color: #11519b;
  text-decoration: underline;
}
