
  .ul {
    justify-content: flex-start;
    width: 100%;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  .li {
    width: 93%;
    font-size: 0.85em;
    margin: 0;
    display: inline-block;
    list-style: none;
    padding: 0;
    position: relative;
    transition: background-color 0.3s;
  }

  .li input {
    background-color: rgba(0, 0, 0, 0.03);
    border-color: rgba(0, 0, 0, 0.09);
  }

  .wrapper {
    height: 76px;
    z-index: 10;
    position: relative;
    align-items: center;
  }

  .logo {
    margin: 0 30px 0 0;
    width: 200px;
    line-height: 1;
  }

  .logoLink {
    display: block;
  }

  .logoImg {
    width: 200px;
  }

  .searchBlock {
    margin-right: auto;
    flex: 1;
    flex-basis: 0;
  }

  .icon {
    font-size: 20px;
  }

  .mobileIconBlockWrapper {
    order: 3;
  }

  @media screen and (max-width: 767px) {
    .logo {
      order: 2;
      text-align: center;
      margin: 0 15px;
    }
  }
