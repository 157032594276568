
  .uploadListWrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
    zoom: 1;
  }

  .uploadListItem {
    position: relative;
    height: 66px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-top: 8px;
    font-size: 14px;
  }

  .uploadListItemInfo {
    height: 100%;
    padding: 0 1.2rem 0 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: background-color 0.3s;
  }

  .uploadListItemName {
    width: 100%;
    max-width: 100%;
    margin: 0 0 0 8px;
    padding-right: 8px;
    overflow: hidden;
    line-height: 2rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.3s;
  }

  .uploadListItemCloseButton {
    font-size: 12px;
    position: absolute;
    top: 8px;
    right: 8px;
    line-height: 1;
    opacity: 1;
  }
