.stepsContentWrapper {
  margin: auto;
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  max-width: 920px;
}

.stepsContentWrapperBlue {
  margin-top: 16px;
  border: 1px dashed #2060a8;
  border-radius: 6px;
  background-color: #2060a8;
  min-height: 200px;
  text-align: center;
  max-width: 920px;
}


.stepsAction {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.wizardFormBlue {
  margin: 1.5rem;
  text-align: left;
  background-color: #2060a8;
}


.wizardForm {
  margin: 1.5rem;
  text-align: left;
}



.stepActionButton {
  margin: 0 1rem;
}
