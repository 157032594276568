
  div.playerWrapper {
    height: 5rem;
  }

  .player {
    display: flex;
    position: fixed;
    bottom: 0;
    height: 5rem;
    width: 100%;
    background: white;
    opacity: 0.9;
    align-items: center;
    padding: 0 2rem;
    border-top: #1e73be 1px solid;
  }

  .actionButton {
    font-size: 1.7rem;
    margin: 0 0.5rem;
    border: none;
    background: none;
    opacity: 0.9;
  }
  .actionButton:hover {
    color: black;
  }

  .playButtonsArea {
    width: 20%;
  }

  .infoArea {
    width: 50%;
    text-align: center;
    height: 100%;
  }

  .progressbarArea {
    display: flex;
    align-items: center;
    height: 2rem;
    font-size: 0.8rem;
  }

  .volumeBarArea {
    width: 10%;
  }

  .progressbarContainer {
    position: relative;
    width: 80%;
    height: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-variant: tabular-nums;
    line-height: 2;
    display: inline-block;
  }
  .waveBarContainer {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    height: 32px;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-variant: tabular-nums;
    line-height: 2;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -webkit-user-drag: none;
  }

  .progressbarTime {
    width: 6%;
  }

  .sampleName {
    height: 1.3rem;
    margin-top: 0.3rem;
  }

  .waveBar {
    position: absolute;
    width: 100%;
    height: 2rem;
  }

  .waveBar wave {
    z-index: 10;
  }
