* {
  margin: 0;
  padding: 0;
}

html {
  font-family: Lato, sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  background-attachment: fixed;
}

body {
  margin: 0;
  color: #777;
  scroll-behavior: smooth;
  line-height: 1.6;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  background-color: transparent;
  text-decoration: none;
}

a:active,
a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
  display: inline-block;
}

img {
  border-style: none;
}

button,
input,
select,
textarea {
  outline: none;
  font: inherit;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

optgroup {
  font-weight: bold;
}

button,
input,
select {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  cursor: pointer;
}

[disabled] {
  cursor: default;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

textarea {
  overflow: auto;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

hr {
  margin: 15px 0;
  border-width: 0;
  border-top: 1px solid currentColor;
  opacity: 0.1;
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
}

figure {
  margin: 0;
}

pre {
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  padding: 20px;
  width: 100%;
  overflow-x: auto;
}

p:empty {
  display: none;
}

a,
button,
input {
  touch-action: manipulation;
}

ins {
  text-decoration: none;
}

embed,
iframe,
object {
  max-width: 100%;
}

.gridContainer {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17%, 1fr));
  grid-template-rows: 1fr;
  gap: 15px;
  grid-template-areas:
          ". . . . ."
          ". . . . ."
          ". . . . .";
}

.layout-center {
  align-tems: flex-start;
  justify-content: center;
  display: flex
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}