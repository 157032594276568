
  .actionButton {
    border: none;
    background: none;
    fill: currentColor;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
  }
  .actionButton:hover {
    fill: black;
  }
