
  .headerTop {
    min-height: 30px;
    /*background-color: #f7f7f7;*/
    display: flex;
    align-items: center;
  }

  .nav {
    position: relative;
    display: flex;
    /*flex-flow: row wrap;*/
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;
    margin-left: 0;
  }

  .menuItem {
    list-style: none;
    position: relative;
    margin: 0 0.7em;
    display: inline-block;
  }
  
  .menuItem:last-child {
    margin-right: 0;
  }

  .menuLink {
    vertical-align: top;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: normal;
    color: rgba(102, 102, 102, 0.85);
    transition: all 0.2s;
    font-size: 0.8em;
    text-decoration: none;
    display: inline-flex;
  }

  .menuLink:after {
    content: '';
    position: absolute;
    top: 50%;
    width: 1px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    height: 15px;
    margin-top: -7px;
    left: -1em;
  }

  .menuLink:hover {
    color: rgba(17, 17, 17, 0.85);
  }
