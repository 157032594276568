
  .gap {
    display: block;
    height: auto;
    padding-top: 44px;
  }

  .main {
    width: 100%;
    margin: auto;
    padding: 24px 2%;
  }
