
  .stuckHeader {
    height: 153px;
  }

  .header {
    width: 100%;
    z-index: 30;
    position: relative;
    display: block;
    background-size: cover;
    background-position: 50% 0;
    transition: background-color 0.3s, opacity 0.3s;
  }

  .headerWrapper {
    width: 100%;
    z-index: 30;
    position: relative;
    background-size: cover;
    background-color: rgba(255, 255, 255, 0.9);
    background-position: 50% 0;
    transition: background-color 0.3s, opacity 0.3s;
  }

  .fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    right: 0;
    bottom: 0;
    padding: 0 !important;
    margin: 0 !important;
  }

  .header-bg-image,
  .header-bg-color {
    background-position: 50% 0;
    transition: background 0.4s;
  }

  .header-top {
    background-color: #446084;
    z-index: 11;
    position: relative;
    min-height: 20px;
  }

  @keyframes stuckMoveDown {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }



  .header.show-on-scroll .hide-for-sticky,
  .stuck .hide-for-sticky {
    display: none;
  }
