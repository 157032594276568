
  .spinner {
    width: 80px;
    height: 80px;
    margin: auto;
    border-radius: 50%;
    border: 10px solid #333;
    box-sizing: border-box;
    animation: sweep 1s linear alternate infinite, rota 0.8s linear infinite;
  }

  @keyframes rota {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes rota {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes sweep {
    0% {
      clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 50% 50%, 0% 0%, 0% 0%, 0% 0%);
    }
    50% {
      clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 50% 50%, 100% 0%, 100% 0%, 0% 0%);
    }
    100% {
      clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 50% 50%, 100% 100%, 100% 0%, 0% 0%);
    }
  }
