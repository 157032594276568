
  .searchWrapper {
    flex: 1;
  }

  .search {
    box-sizing: border-box;
    border: 1px solid #ddd;
    padding: 0 0.75em;
    height: 2.507em;
    font-size: 0.97em;
    max-width: 100%;
    width: 100%;
    vertical-align: middle;
    background-color: #fff;
    color: currentColor;
    transition: color 0.3s, border 0.3s, background 0.3s, opacity 0.3s;
    box-shadow: none;
    border-radius: 99px;
    cursor: text;
  }

  .btnWrapper {
    margin-left: -32px;
  }

  .btn {
    margin: 0;
    color: currentColor;
    border-color: transparent;
    background-color: transparent;
    box-shadow: none;
    width: 32px;
    padding: 0;
    display: inline-block;
    cursor: pointer;
    height: 32px;
    font-size: 13px;
  }
