
  .ul {
    padding-bottom: 5px;
    opacity: 0.8;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    margin: 5px 0;
    font-size: 0.85em;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  .li {
    display: inline-block;
  }

  .li:not(:first-child) {
    margin: 0 0.4em;
  }

  .link {
    color: currentColor;
  }

  .wrapper {
    color: white;
    padding: 15px 0;
    font-family: ArialNarrowRegular;
    font-size: 1.2em;
    background-color: #2670c0;
  }

  @media (max-width: 767px) {
    .wrapper {
      text-align: center;
    }
  }

  .paymentIconsWrapper {
    display: inline-block;
    color: rgba(255, 255, 255, 0.5);
  }
  
  .paymentImage {
    height: 40px
  }

  @media (min-width: 768px) {
    .paymentIconsWrapper {
      text-align: right;
    }
  }

  .paymentIcon {
    display: inline-block;
    opacity: 0.6;
    transition: opacity 0.3s;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 3px 5px 5px;
    margin: 3px 3px 0;
    font-size: 0.9em;
  }

  .paymentIconSvg {
    fill: #fff;
    vertical-align: middle;
    height: 20px;
  }
