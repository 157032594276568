
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1500px;
  }
  
  .sameTracksTable tbody {
  	margin-left: 10px;
  }
  
  .sameTracksTable  tbody .samplesTable {
    width: 100% ;
  }
  
  .sameTracksTable tbody {
  	background: rgba(12%, 45%, 75%, 0.1);
  }
  
  table.samplesTable {
    
  }

  .details {
    display: flex;
    flex-wrap: wrap;
    width: 82%;
  }

  .details dt {
    width: 21%;
    line-height: 1.5rem;
  }

  .details dd {
    width: 60%;
    margin: 0;
    line-height: 1.5rem;
  }

  .samplesWrapper {
    width: 50%;
  }


  .samplesTable td {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    width: unset;
  }
  
  .trackExpandEl{
    
  }
  
