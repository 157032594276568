
  .sampleUploadButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.8rem 0.8rem 0.8rem 0;
    text-align: center;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    transition: border-color 0.3s ease;
    width: 100%;
    height: 4rem;
    vertical-align: middle;
  }

  .sampleUploadButton:hover {
    border-color: #1890ff;
  }
