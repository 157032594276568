
  .tag {
    color: currentColor;
    font-size: 1em;
    text-decoration: none;
    background-color: transparent;
    display: inline-block;
    border: 1px solid currentColor;
    opacity: 0.8;
    margin: 0 3px 5px 0;
    padding: 2px 5px;
    border-radius: 3px;
  }

  .tag:hover {
    color: #fff;
    border-color: #11519b;
    background-color: #11519b;
    opacity: 1;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .wrapper {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    background-color: #254061;
    padding-left: 10px;
    font-family: ArialNarrowRegular;
    padding: 30px 0 0;
    color: #f1f1f1;
    animation: fadeIn 0.1s;
  }

  .widgetWrapper {
    text-align: left;
    margin-bottom: 2.5em;
  }

  .widgetTitle {
    font-size: 1.2em;
    font-weight: 600;
    line-height: 1.25;
    padding-bottom: 10px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #40a9ff
  }

  .widgetTitleDivider {
    background-color: rgba(255, 255, 255, 0.3);
    /*margin-top: .66em;*/
    height: 3px;
    display: block;
    margin: 1em 0 1em;
    width: 100%;
    max-width: 30px;
  }

  .lastNewsBadgeLi {
    list-style: none;
    margin-bottom: 0.3em;
    text-align: left;
  }

  .lastNewsBadgeLi:not(:first-child) {
    border-top: 1px solid #ececec;
    border-color: rgba(255, 255, 255, 0.08);
  }

  .badgeWrapper {
    padding: 10px 0 10px;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .badgeDateWrapper {
    margin-right: 15px;
    max-height: 100%;
  }

  .badgeDate {
    top: 7%;
    margin: 0;
    width: 2.3em;
    height: 2.3em;
    display: table;
    z-index: 20;
    pointer-events: none;
    backface-visibility: hidden;
  }

  .badgeDateInner {
    color: #fff;
    opacity: 0.7;
    background-color: transparent;
    border: 2px solid currentColor;
    line-height: 0.7;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: 50% 50%;

    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 100%;
    height: 100%;
    font-weight: bolder;
    padding: 2px;
    white-space: nowrap;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
  }

  .badgeDateMonth {
    font-size: 0.9em;
  }

  .badgeTitle {
    color: currentColor;
    line-height: 1.2;
    display: block;
    margin-bottom: 6px;
  }

  .badgeComments {
    color: currentColor;
    opacity: 0.7;
    display: block;
    font-size: 0.9em;
    line-height: 1.2;
    margin-bottom: 6px;
  }
  
   .link {
    color: currentColor;
    font-size: 16px;
  }
  
  ul {
    line-height: 1.2;
    margin-left: 20px;
  }

  .logoImg{
    height: 120px;
  }
