.ant-form-item-children span.ant-form-item-children-icon {
  z-index: 2 !important;
}

.AdminCarouselManagePage .ant-upload-list-picture-card .ant-upload-list-item {
  width: 500px;
}

.AdminCarouselManagePage .ant-upload-list-picture-card-container {
  width: 500px;
}

.ant-collapse-header {
  font-weight: bold;
}

.ant-table-tbody > tr > td {
  padding: 8px 16px !important;
}

.ant-table-tbody > tr.ant-table-row-level-0:hover > td {
  background: unset !important;
}

.ant-table-row-level-0:has(.ant-table-row-expand-icon-expanded) {
  background: rgba(12%, 45%, 75%, 0.1);
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #fafafa;
}

/*table:has(.ant-table-row-expand-icon-expanded) .ant-table-expanded-row-level-1 {
  margin-bottom: 40px;
}*/
