
  .direction {
  	transition: all ease 0.3s;
  	aspect-ratio: 1;
  	
  	background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 0 7px rgba(0,0,0,0.3);
    display: flex;
    /*align-items: center;
    justify-content: center;*/
    cursor: pointer;
    /*padding: 16px;*/
    border-radius: 3px;
    
    filter: brightness(1) saturate(.5);
  }
  
  .directionName {
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
    //margin-top: 16px;
    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .direction:hover .directionImg:not(.directionName) {
  transition: all ease 0.3s;
  	transform: scale(1.05);
  }
  
  .direction:hover {
  	box-shadow: 0 0 15px rgba(0,0,0,0.3);
  	filter: brightness(1.1) saturate(1);
  	//background-size: 105%;
  }

