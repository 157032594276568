
  .volumeBarInput {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0.01;
    z-index: 4;
    cursor: pointer;
    height: 1rem;
  }
  input[type='range']:focus {
    outline: none;
  }
  input[type='range']::-webkit-slider-thumb {
    height: 1px;
    width: 1px;
    border-radius: 3px;
    background: #b1ffff;
    cursor: pointer;
    -webkit-appearance: none;
  }

  .volumeBarWrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .volumeBarContainer {
    position: relative;
    width: 80%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-variant: tabular-nums;
    line-height: 1.5;
    display: inline-block;
    height: 0.3rem;
    background-color: #f5f5f5;
    border-radius: 100px;
    overflow: hidden;
    vertical-align: middle;
    cursor: pointer;
  }

  .bar {
    position: absolute;
    z-index: 2;
    background-color: #1e73be;
    border-radius: 100px;
    height: 0.3rem;
    width: 100%;
  }
